<template>
  <div id="contact">
    <h1 class="a1g" :class="{'padding-has-caution': isHasCaution}">お問い合わせ</h1>
    <div class="block">
      <iframe src="https://go.pardot.com/l/924602/2022-07-15/bgvnn"
              width="100%"
              type="text/html" frameborder="0"
              allowTransparency="true">
      </iframe>
    </div>
  </div>
</template>

<script>
import Common from '@/mixins/common.mixin'
import { mapState } from 'vuex'

export default {
  name: 'About',

  mounted () {
    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})
    }
  },
  computed: {
    ...mapState('auth', ['profile']),
    isHasCaution () {
      return !Common.methods.checkWebview() && ((this.profile && this.profile.skip_regis_info) || Common.methods.checkMobile())
    }
  }
}
</script>

<style lang="scss" scoped>
//------------------------------------------------------------------ Color ---*/
$orange: #C66C44;
$orange-gray: #D98F6D;
$orange-bg: #F5F0ED;
//------------------------------------------------------------ Media mixin ---*/

@function vw($num) {
  $maxWidth: 1128; //レイアウト最大幅
  // $val_vw: round($num / $maxWidth * 100 * 1000) / 1000;
  $val_vw: calc(round($num / $maxWidth * 100 * 1000) / 1000);
  $val_max: round($maxWidth * ($val_vw / 100)) * 1px;
  @return unquote('min(#{$val_vw * 1vw}, #{$val_max})');
}

$viewport: 767;
@function pv($num, $width: $viewport) {
  // @return (1vw * $num / $width * 100);
  @return calc(1vw * $num * 100 / $width);
}

//--------------------------------------------------------------- Contents ---*/
.a1g {
  font-family: $font-family-title;
}

#contact {
  max-width: 1160px;
  padding: 0 15px;
  width: 100%;
  background-color: #fff;
  margin: 0 auto;
  overflow-x: hidden;
  @media #{$info-phone} {
    max-width: 100%;
    width: 100%;
    background-color: #fff;
    margin: 0 auto;
    overflow-x: hidden;
  }

  h1 {
    font-size: vw(36);
    color: $orange;
    text-align: center;
    margin: vw(100) auto vw(55);
    @media #{$info-phone} {
      font-size: pv(64);
      color: $orange;
      text-align: center;
      margin: 10vw auto;
    }
  }
  .padding-has-caution {
    @media #{$info-phone} {
      padding-top: 50px;
    }
  }
  .block {
    margin: 0 auto;
    max-width: 100%;
    iframe {
      min-height: 600px;
    }
  }

}
</style>
